import request from '@/services/axios_instance'
import { mapGetters } from 'vuex'
import _ from 'lodash'
export const support_mixin = {
    data: () => ({
        submitting: false
    }),
    computed: {
        ...mapGetters(['user', 'users']),
        ...mapGetters('supports', ['handlers']),
        can_handle_tickets() {
            return this.user.is_super_admin ||
                this.handlers.some((v) => v.id == this.user.id) ?
                true :
                false
        },
        developers() {
            return _.cloneDeep(this.users).filter(
                (i) => i.is_super_admin || this.handlers.some((v) => v.id === i.id)
            )
        }
    },
    methods: {
        handleSavePost(payload, cb) {
            this.submitting = true
            request
                .post(`api/supports/post`, payload)
                .then(({ data }) => {
                    if (typeof cb === 'function') {
                        cb(data)
                    }
                })
                .finally(() => (this.submitting = false))
        },
        handleUpdatePost(id, payload, cb) {
            this.submitting = true
            request
                .put(`api/supports/post/${id}`, payload)
                .then(({ data }) => {
                    if (typeof cb === 'function') {
                        cb(data)
                    }
                })
                .finally(() => (this.submitting = false))
        },
        handleDeletePost(item, cb) {
            if (item) {
                let ask = 'Delete this post?'
                if (item.type === 'updates') ask = 'Delete this update?'
                else if (item.type === 'upcoming-updates')
                    ask = 'Delete this upcoming update?'
                else if (item.type === 'faqs') ask = 'Delete this FAQ entry?'
                this.appConfirmation(ask, () => {
                    request.delete(`api/supports/post/${item.id}`).then(({ data }) => {
                        this.appSnackbar(data.message)
                        if (typeof cb === 'function') {
                            cb(data)
                        }
                    })
                })
            }
        }
    }
}