<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    @button2="save"
    title="Manage Support Handlers"
    button1-text="Close"
    button2-text="Save"
    :main-btn-disabled="!valid"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text>
          <v-item-group mandatory multiple v-model="selected">
            <v-row dense align="start" justify="center" class="flex-wrap">
              <v-item
                :value="user.id"
                v-slot="{ active, toggle }"
                v-for="user in can_handle_supports"
                :key="user.id"
              >
                <v-col cols="12" md="3" sm="6">
                  <v-card
                    min-height="150"
                    @click="toggle"
                    elevation="3"
                    :disabled="user.is_owner || user.is_admin"
                    :class="{ 'primary light--text': active }"
                    class="
                      d-flex
                      rounded-10
                      fullwidth
                      text-center
                      align-center
                      justify-center
                      flex-column
                    "
                  >
                    <v-avatar>
                      <v-img
                        :lazy-src="settings.loader"
                        :src="user.image_url"
                      ></v-img>
                    </v-avatar>
                    <strong>{{ user.fullname }}</strong>
                    <span class="caption"> {{ user.job_title }} </span>
                  </v-card>
                </v-col>
              </v-item>
            </v-row>
          </v-item-group>
        </v-card-text>
        <v-card-actions>
          <div class="fs-11 fw-500 secondary--text">
            Note: Unselecting the current existing handlers will also unassign
            all its non-closed assigned tickets.
          </div>
        </v-card-actions>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  name: 'ManageSupportHandler',
  components: { CustomDialog },
  props: {
    value: { type: Boolean, default: false }
  },
  data: () => ({ dialog: false, selected: [] }),
  computed: {
    ...mapGetters(['users', 'user']),
    ...mapGetters('supports', ['handlers']),
    can_handle_supports() {
      return this.users.filter((i) => !i.is_client)
    },
    valid() {
      return this.selected.length
    }
  },
  watch: {
    value(val) {
      this.dialog = val
      if (val) {
        this.selected = _.cloneDeep(this.handlers).map((i) => i.id)
      }
    },
    dialog(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    ...mapActions('supports', ['fetchHandlers', 'updateHandlers']),
    close() {
      this.dialog = false
    },
    save() {
      this.updateHandlers({
        payload: { ids: this.selected },
        cb: () => {
          this.close()
          this.$event.$emit('handlers-updated', this.selected)
          this.appSnackbar('Handlers updated.')
        }
      })
    }
  }
}
</script>
<style></style>
