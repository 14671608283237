<template>
  <v-card
    min-height="100vh"
    outlined
    flat
    class="rounded-10 overflow-hidden support"
  >
    <v-card-title style="min-height: 200px" class="primary d-block rounded-0">
      <v-row dense align="center" justify="space-between">
        <v-breadcrumbs light :items="paths">
          <template v-slot:divider>
            <v-icon color="white">mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
              <span class="white--text">{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <div v-if="user.is_super_admin">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-1" icon v-bind="attrs" v-on="on">
                <v-icon color="white">mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="open_manage_tags_dialog = true">
                <v-list-item-title class="primary--text">
                  Manage Tags
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="open_manage_handlers = true">
                <v-list-item-title class="primary--text">
                  Manage Support Handler
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-row>
      <v-row dense align="center" justify="center">
        <h1 class="white--text fw-900 fs-30 my-5 text-center">
          {{ settings.SiteName }} Support
        </h1>
      </v-row>
      <v-row dense align="center" justify="end">
        <v-btn
          @click="$router.go(-1)"
          class="text-none"
          width="100"
          color="white"
          depressed
        >
          Back
        </v-btn>
        <v-btn
          v-if="$route.name != 'support-management' && can_handle_tickets"
          @click="$router.push({ name: 'support-management' })"
          color="white"
          depressed
          class="px-5 text-none ml-1"
        >
          Manage Tickets
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <router-view></router-view>
    </v-card-text>

    <ManageTagsDialog v-model="open_manage_tags_dialog"></ManageTagsDialog>
    <ManageSupportHandler v-model="open_manage_handlers"></ManageSupportHandler>
  </v-card>
</template>

<script>
import store from '@/store/store'
import { mapActions } from 'vuex'
import ManageSupportHandler from './components/ManageSupportHandler.vue'
import ManageTagsDialog from './components/ManageTagsDialog.vue'
import ReportBugDialog from './components/ReportBugDialog.vue'
import { support_mixin } from './support_mixin'
export default {
  components: { ReportBugDialog, ManageTagsDialog, ManageSupportHandler },
  mixins: [support_mixin],
  data: () => ({
    open_manage_tags_dialog: false,
    open_manage_handlers: false,
    paths: [
      {
        text: 'Dashboard',
        disabled: true,
        href: '/dashboard'
      },
      {
        text: 'Support',
        disabled: true,
        href: '/dashboard/support'
      }
    ],
    tabs: [
      { label: 'General', route: { name: 'support' } },
      {
        label: 'Recent Updates',
        route: { name: 'support-updates-and-fixes' }
      },
      { label: 'FAQs and Guides', route: { name: 'support-faqs' } }
    ]
  }),
  created() {
    this.fetchTags(true)
  },
  methods: {
    ...mapActions('supports', ['submitTicket', 'fetchTags'])
  },
  beforeRouteEnter(to, from, next) {
    return store.dispatch('supports/fetchHandlers').then(next)
  }
}
</script>
<style lang="scss" scoped></style>
