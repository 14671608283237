<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    title="Manage Tags"
    button1-text="Close"
    :has-button2="false"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text>
          <v-item-group>
            <v-row dense>
              <v-item :value="tag.id" v-for="tag in tags" :key="tag.id">
                <v-col cols="12" md="12" sm="12">
                  <v-sheet
                    outlined
                    class="
                      pa-2
                      d-flex
                      align-center
                      fullwidth
                      rounded-10
                      justify-space-between
                    "
                  >
                    <strong class="primary--text">
                      {{ tag.name | ucwords }} (Tickets: {{ tag.counts || 0 }})
                    </strong>
                    <HelpToolTip
                      @click:icon="handleDeleteTag(tag)"
                      icon="mdi-delete"
                      icon-color="error"
                      text="Delete tag"
                    >
                    </HelpToolTip>
                  </v-sheet>
                </v-col>
              </v-item>
            </v-row>
          </v-item-group>
          <v-sheet class="mt-1 d-flex align-start justify-space-between">
            <v-text-field
              placeholder="Enter Tag Name*"
              hide-details="auto"
              dense
              :rules="[requiredRule()]"
              v-model="name"
              outlined
            ></v-text-field>
            <v-btn
              :disabled="!name || submitting"
              :loading="submitting"
              @click="handleAddTag"
              class="text-none ml-1"
              color="primary"
              depressed
            >
              Add
            </v-btn>
          </v-sheet>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  name: 'ManageTagsDialog',
  components: { CustomDialog },
  props: { value: { type: Boolean, default: false }, item: { type: Object } },
  data: () => ({ dialog: false, valid: false, name: null }),
  computed: {
    ...mapGetters('supports', ['tags', 'submitting']),
    isEdit() {
      return this.item ? true : false
    },
    payload() {
      return {}
    }
  },
  watch: {
    value(val) {
      this.dialog = val
      if (val) {
        this.name = null
      }
    },
    dialog(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    ...mapActions('supports', ['deleteTag', 'addTag']),
    close() {
      this.dialog = false
    },
    handleDeleteTag(tag) {
      this.appConfirmation('Delete this tag?', () => {
        this.deleteTag({
          id: tag.id,
          cb: () => {
            this.appSnackbar('Tag deleted!')
          }
        })
      })
    },
    handleAddTag() {
      this.addTag({
        payload: { name: this.name },
        cb: () => {
          this.appSnackbar('Tag created')
          this.name = null
        }
      })
    }
  }
}
</script>
<style></style>
