<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    :title="title"
    :button2-text="isEdit ? `Update` : `Save`"
    @button2="save"
    max-width="800"
    :main-btn-disabled="!valid"
  >
    <template v-slot:content>
      <v-sheet>
        <label class="primary--text subtitle-2">Bug/Request Title</label>
        <v-text-field
          outlined
          hide-details="auto"
          dense
          v-model="mapping.title"
          :rules="[requiredRule()]"
        ></v-text-field>
        <v-sheet flat class="py-2 d-flex align-center justify-start px-1">
          <span class="subtitle-2 primary--text mr-3">Tags</span>
          <v-chip-group
            multiple
            mandatory
            dense
            :rules="[requiredRule()]"
            v-model="mapping.tags"
            active-class="primary--text"
          >
            <v-chip
              :value="tag.id"
              filter
              outlined
              v-for="tag in tags"
              :key="tag.id"
            >
              {{ tag.name }}
            </v-chip>
          </v-chip-group>
        </v-sheet>
        <label class="primary--text subtitle-2">
          Bug/Request/Other Details
        </label>
        <div class="caption secondary--text">
          For bug reporting. Please provide the following.<br />
          <ul>
            <li>Browser used (Chrome, Firefox, etc)</li>
            <li>Url or name of the page where the bug/error occured</li>
            <li>Screenshot or video on how to replicate if posible</li>
          </ul>
        </div>
        <Editor required v-model="mapping.details" min-height="150"></Editor>
      </v-sheet>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import Editor from '@/common/Editor/Editor.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'ReportBugDialog',
  components: { CustomDialog, Editor },
  props: { value: { type: Boolean, default: false }, item: { type: Object } },
  data: () => ({
    dialog: false,
    mapping: {
      title: null,
      details: null,
      tags: []
    }
  }),
  computed: {
    ...mapGetters('supports', ['tags']),
    valid() {
      return this.mapping.title &&
        this.mapping.details &&
        this.mapping.tags.length
        ? true
        : false
    },
    title() {
      return this.isEdit ? 'Update Ticket' : 'New Ticket'
    },
    isEdit() {
      return this.item ? true : false
    },
    payload() {
      return this.mapping
    }
  },
  watch: {
    value(val) {
      this.dialog = val
      if (val && !this.isEdit) {
        this.mapping = {
          title: null,
          details: null,
          tags: []
        }
      }
    },
    dialog(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    save() {
      this.$emit(this.isEdit ? 'update' : 'save', this.payload)
    }
  }
}
</script>
<style></style>
